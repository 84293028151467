import { FC } from 'react';
import { TodoItemProps } from './TodoItem.types';
import styles from './todo-item.module.scss';
import { Button, Typography } from '@sensible/components';

const TodoItem: FC<TodoItemProps> = ({ title, text, onClick, buttonLabel }) => {
  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <Typography variant="h5" format="title">
          {title}
        </Typography>
        <Typography variant="body-3">{text}</Typography>
      </div>
      <div className={styles.buttonContainer}>
        <Button clickHandling={onClick} label={buttonLabel} errorMsg="" />
      </div>
    </div>
  );
};

export default TodoItem;
