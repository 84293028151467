import { FC } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Typography } from '@sensible/components';

const ApiIntegration: FC = () => {
  const API_DOCS_URL = 'https://sensible.readme.io/docs';
  return (
    <>
      <PageTitle>Guides: API Integration</PageTitle>
      <SectionHeader>Get API Access</SectionHeader>
      <Typography variant="body-3">
        Contact{' '}
        <a href="mailto:partnerships@sensibleweather.com">
          partnerships@sensibleweather.com
        </a>{' '}
        if you are interested in integrating with our API.
      </Typography>
      <br />
      <Typography variant="body-3">
        <a href={API_DOCS_URL} target="_blank" rel="noreferrer">
          <u>API Docs</u>
        </a>
      </Typography>
    </>
  );
};

export default ApiIntegration;
