import React, { FC } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@sensible/components';
import NavigationListItem from '../NavigationListItem/NavigationListItem';
import { NavigationLinkProps } from './NavigationLink.types';
import styles from './navigation-link.module.scss';

const NavigationLink: FC<NavigationLinkProps> = (props) => {
  const location = useLocation();
  const className =
    location.pathname === props.to ? styles.currentLink : styles.link;
  return (
    <NavigationListItem>
      <Link {...props}>
        <Typography variant="h5" color="snow" className={className}>
          {props.label}
        </Typography>
      </Link>
    </NavigationListItem>
  );
};

export default NavigationLink;
