import { Typography } from '@sensible/components';
import styles from './breadcrumb.module.scss';
import { BreadcrumbProps } from './Breadcrumb.types';

const Breadcrumb: React.FunctionComponent<BreadcrumbProps> = ({
  items,
  current,
  className,
}) => {
  return (
    <Typography className={className} variant="body-1" format="title">
      {items.map((item, index) => (
        <span
          key={`breadcrumb-${index}`}
          className={index === current ? styles.selected : styles.item}
        >
          {`${index + 1}. ${item}`}
        </span>
      ))}
    </Typography>
  );
};

export default Breadcrumb;
