import { FC, useEffect, useState } from 'react';
import PageTitle from '../PageTitle/PageTitle';
import SectionHeader from '../SectionHeader/SectionHeader';
import {
  Button,
  DateInput,
  DropdownInputSingleSelect,
  Grid,
  TextInput,
  Typography,
} from '@sensible/components';
import TextParameterItem from '../TextParameterItem/TextParameterItem';
import styles from './post-booking-profile.module.scss';
import {
  PostBookingFields,
  PostBookingProfileProps,
} from './PostBookingProfile.types';
import { SensibleDate, CurrencyUnit } from '@sensible/utils';

const defaultArgs: PostBookingFields = {
  exposure_name: 'Maytock Resort, Miami',
  exposure_latitude: '37.77664018315584',
  exposure_longitude: '-111.63836828825272',
  coverage_start_date: new Date(2022, 3, 23),
  coverage_end_date: new Date(2022, 3, 25),
  exposure_total_coverage_amount: '779.46',
  currency: CurrencyUnit.USD,
  reservation_id: 'abc123',
  host: 'https://addon.sensibleweather.io/',
};

const asHalfGrid = <T,>(Component: FC<T>): FC<T> => {
  const Wrapped: FC<T> = (props) => {
    return (
      <Grid item md={6} xs={12} className={styles.gridItem}>
        <Component {...props} />
      </Grid>
    );
  };
  return Wrapped;
};

const TextParameterHalfGrid = asHalfGrid(TextParameterItem);
const DropDownHalfGrid = asHalfGrid(DropdownInputSingleSelect);
const TextInputHalfGrid = asHalfGrid(TextInput);
const DateSelectHalfGrid = asHalfGrid(DateInput);

const PostBookingProfile: FC<PostBookingProfileProps> = ({
  partner,
  profile,
}) => {
  const [fields, setFields] = useState<PostBookingFields>();
  useEffect(() => {
    setFields(defaultArgs);
  }, []);

  if (!fields || !partner) {
    return <></>;
  }

  const {
    host,
    exposure_name,
    exposure_latitude,
    exposure_longitude,
    coverage_start_date,
    coverage_end_date,
    exposure_total_coverage_amount,
    currency,
    reservation_id,
  } = fields;
  const partner_id = partner.profiles[0].userID || '';

  const formatDate = (date: Date): string =>
    date.toLocaleDateString('en-us', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });

  const url = new URL(host);
  url.searchParams.set('partner_id', partner_id);
  url.searchParams.set('exposure_name', exposure_name);
  url.searchParams.set('exposure_latitude', exposure_latitude);
  url.searchParams.set('exposure_longitude', exposure_longitude);
  url.searchParams.set('coverage_start_date', formatDate(coverage_start_date));
  url.searchParams.set('coverage_end_date', formatDate(coverage_end_date));
  url.searchParams.set(
    'exposure_total_coverage_amount',
    exposure_total_coverage_amount
  );
  url.searchParams.set('currency', currency);
  url.searchParams.set('reservation_id', reservation_id);

  const openTestUrl = (): void => {
    window.open(url, '_blank');
  };

  return (
    <>
      <PageTitle>Guides: Post-booking</PageTitle>
      <SectionHeader>
        Created Testing Links: {profile.name} ({profile.environment})
      </SectionHeader>
      <Grid container>
        <TextParameterHalfGrid label="partner_id" value={partner_id} />
        <TextInputHalfGrid
          value={exposure_name}
          handleChange={(value): void => {
            setFields({
              ...fields,
              exposure_name: value,
            });
          }}
          label="exposure_name"
        />
        <TextInputHalfGrid
          value={exposure_latitude}
          handleChange={(value): void => {
            setFields({
              ...fields,
              exposure_latitude: value,
            });
          }}
          label="exposure_latitude"
        />
        <TextInputHalfGrid
          value={exposure_longitude}
          handleChange={(value): void => {
            setFields({
              ...fields,
              exposure_longitude: value,
            });
          }}
          label="exposure_longitude"
        />
        <DateSelectHalfGrid
          label="coverage_start_date"
          handleChange={(date: SensibleDate): void =>
            setFields({ ...fields, coverage_start_date: date })
          }
          value={coverage_start_date}
        />
        <DateSelectHalfGrid
          label="coverage_end_date"
          handleChange={(date: SensibleDate): void =>
            setFields({ ...fields, coverage_end_date: date })
          }
          value={coverage_end_date}
        />
        <TextInputHalfGrid
          value={exposure_total_coverage_amount}
          handleChange={(value): void => {
            setFields({
              ...fields,
              exposure_total_coverage_amount: value,
            });
          }}
          label="exposure_total_coverage_amount"
        />
        <DropDownHalfGrid
          label="currency"
          options={[
            { label: 'USD', value: CurrencyUnit.USD },
            { label: 'EUR', value: CurrencyUnit.EUR },
          ]}
          onChangeOption={(option): void =>
            setFields({ ...fields, currency: option.value as CurrencyUnit })
          }
        />
        <TextInputHalfGrid
          value={reservation_id}
          handleChange={(value): void =>
            setFields({ ...fields, reservation_id: value })
          }
          label="reservation_id"
        />
        <Grid item xs={12} className={styles.url}>
          <Typography variant="body-4">{url.toString()}</Typography>
          <Button
            clickHandling={openTestUrl}
            label="View this url"
            errorMsg=""
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PostBookingProfile;
