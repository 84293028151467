import { FC, ReactElement, useContext } from 'react';
import { OrganizationContext } from '../../contexts/organizationContext';
import { PostBookingProfile } from '../../components';

const PostBooking: FC = () => {
  const { partner } = useContext(OrganizationContext);
  const renderProfiles = (): ReactElement[] | undefined => {
    return partner?.profiles.map((profile) => (
      <PostBookingProfile
        profile={profile}
        partner={partner}
        key={`${profile.name}-${profile.environment}`}
      />
    ));
  };

  return <>{renderProfiles()}</>;
};

export default PostBooking;
