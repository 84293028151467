export interface IOrganization {
  id: string;
  displayName: string;
}

export function assertsIsOrganization(
  organization: unknown
): asserts organization is IOrganization {
  const org = organization as IOrganization;
  if (!org.displayName || typeof org.displayName !== 'string') {
    throw 'Organization does not match expected type: Missing field `displayName`';
  }
  if (!org.id || typeof org.id !== 'string') {
    throw 'Organization does not match expected type: Missing field `id`';
  }
}
