import React from 'react';
import { assets } from '@sensible/components';

import styles from './navigation-panel.module.scss';
import NavigationLink from '../../components/NavigationLink/NavigationLink';
import NavigationAccordion from '../../components/NavigationAccordion/NavigationAccordion';
import NavigationAccordionItem from '../../components/NavigationAccordionItem/NavigationAccordionItem';
import { useAuth0 } from '@auth0/auth0-react';
import NavigationLinkButton from '../../components/NavigationLinkButton/NavigationLinkButton';

const NavigationPanel: React.FC = () => {
  const { logout } = useAuth0();
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img
          src={assets.png.sensible.DarkSensibleLogo}
          className={styles.logo}
          alt="Sensible Weather logo"
        />
      </div>
      <ul>
        <NavigationLink to="/" label="Home" />
        <NavigationAccordion label="Guide">
          <NavigationAccordionItem
            label="Post-Booking"
            to="/guides/post-booking"
          />
          <NavigationAccordionItem
            label="API Integration"
            to="/guides/api-integration"
          />
        </NavigationAccordion>
        <NavigationLink to="/help" label="Help" />
      </ul>

      <div className={styles.footerLinkContainer}>
        <NavigationLinkButton
          onClick={(): void => logout({ returnTo: window.location.origin })}
          label="Logout"
        />
      </div>
    </div>
  );
};

export default NavigationPanel;
