import { LayoutProps } from '../Layout.types';
import { NavigationPanel, NavigationMobile } from '../../containers';
import styles from './portal-layout.module.scss';

const PortalLayout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  return (
    <>
      <div id="container">
        <div
          id="navigation-side-panel"
          className={[styles.navigationSidePanel, styles.fixed].join(' ')}
        >
          <NavigationPanel />
        </div>
        <div
          id="navigation-mobile"
          className={[styles.navigationMobile, styles.fixed].join(' ')}
        >
          <NavigationMobile />
        </div>
        <div id="content" className={styles.content}>
          {children}
        </div>
      </div>
    </>
  );
};

export default PortalLayout;
