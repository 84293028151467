import React, { useState } from 'react';
import { NavigationAccordionProps } from './NavigationAccordion.types';
import { LinkProps, useLocation } from 'react-router-dom';
import { Typography } from '@sensible/components';
import styles from './navigation-accordion.module.scss';
import NavigationListItem from '../NavigationListItem/NavigationListItem';

const NavigationAccordion: React.FC<NavigationAccordionProps> = ({
  children,
  label,
}) => {
  const location = useLocation();
  const initialOpen = React.Children.toArray(children).find(
    (child) =>
      (child as React.ReactElement<LinkProps>).props.to === location.pathname
  );

  const [open, setOpen] = useState(initialOpen);
  const contents = open ? <ul>{children}</ul> : null;

  const caret = open ? 'v' : '>';

  const toggleOpen = (): void => setOpen(!open);
  return (
    <NavigationListItem>
      <div onClick={toggleOpen} role="presentation">
        <Typography variant="h5" color="snow" className={styles.accordion}>
          <span>{label}</span>
          <span>{caret}</span>
        </Typography>
      </div>
      {contents}
    </NavigationListItem>
  );
};

export default NavigationAccordion;
