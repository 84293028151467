import { err } from '@sensible/utils';
import axios, { AxiosResponse } from 'axios';
import { Config } from '../types/config';

const getConfig = async (): Promise<Config> => {
  const URL = '/api/config/client';

  try {
    const res: AxiosResponse<Record<string, unknown>> = await axios.get(URL);
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }
    const { data } = res;
    const { reactAuthClientID, authDomain, partnerAuthAudience } = data;

    if (!reactAuthClientID || typeof reactAuthClientID !== 'string') {
      throw new Error('reactAuthClientID must be present and of type string');
    }
    if (!authDomain || typeof authDomain !== 'string') {
      throw new Error('reactAuthDomain must be present and of type string');
    }
    if (!partnerAuthAudience || typeof partnerAuthAudience !== 'string') {
      throw new Error('partnerAuthAudience must be present and of type string');
    }

    return { reactAuthClientID, authDomain, partnerAuthAudience };
  } catch (e) {
    throw err.handleError(e, 'error fetching client config');
  }
};

export default {
  getConfig,
};
