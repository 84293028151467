import { FC } from 'react';
import { EnvironmentKeysProps } from './EnvironmentKeys.types';
import SectionHeader from '../SectionHeader/SectionHeader';
import TextParameterItem from '../TextParameterItem/TextParameterItem';
import styles from './environment-keys.module.scss';

const EnvironmentKeys: FC<EnvironmentKeysProps> = ({
  environmentName,
  partnerId,
}) => {
  return (
    <div className={styles.container}>
      <SectionHeader>{environmentName}</SectionHeader>
      <TextParameterItem label="Partner ID" value={partnerId} />
    </div>
  );
};

export default EnvironmentKeys;
