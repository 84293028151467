import { Typography } from '@sensible/components';
import PartnerHeader from '../PartnerHeader/PartnerHeader';
import { HeaderProps } from './OnboardingHeader.types';

const OnboardingHeader: React.FunctionComponent<HeaderProps> = ({
  name,
  partnerDisplay,
}) => {
  return (
    <>
      <PartnerHeader partnerDisplay={partnerDisplay} />
      <Typography variant="body-1">Welcome {name},</Typography>
      <Typography variant="body-1">
        You can begin offering your customers a Weather Guarantee by answering a
        few questions about your business and then we&apos;ll provide options
        for integrating.
      </Typography>
    </>
  );
};

export default OnboardingHeader;
