import { LayoutProps } from '../Layout.types';
import styles from './onboarding-layout.module.scss';
import { useContext } from 'react';
import { OrganizationContext } from '../../contexts/organizationContext';
import { useAuth0 } from '@auth0/auth0-react';
import OnboardingHeader from '../../components/OnboardingHeader/OnboardingHeader';

const OnboardingLayout: React.FunctionComponent<LayoutProps> = ({
  children,
}) => {
  const organizationEnvelope = useContext(OrganizationContext);
  const { user } = useAuth0();
  return (
    <div className={styles.container}>
      <OnboardingHeader
        name={user?.name || ''}
        partnerDisplay={organizationEnvelope.organization?.displayName || ''}
      />
      {children}
    </div>
  );
};

export default OnboardingLayout;
