import React, { FC } from 'react';
import { TodoItemProps } from '../../components/TodoItem/TodoItem.types';
import PageTitle from '../../components/PageTitle/PageTitle';
import TodoItem from '../../components/TodoItem/TodoItem';
import SectionHeader from '../../components/SectionHeader/SectionHeader';

const TodoList: FC = () => {
  const todoItems: TodoItemProps[] = [
    {
      title: 'Implement and Test Post-Booking Flow',
      text: 'Our implementation guide will walk you through the steps for building links and testing the implementation.',
      buttonLabel: 'Implementation Guide',
      onClick: () => alert('TODO: implementation guide'),
    },
    {
      title: 'Sign Partnerships Agreement',
      text: 'Sign the partnerships agreement in order to launch to production.',
      buttonLabel: 'Sign Partnerships Agreement',
      onClick: () => alert('TODO: partnership agreement'),
    },
  ];

  return (
    <div>
      <PageTitle>Home</PageTitle>
      <SectionHeader>To-Do</SectionHeader>
      {todoItems.map((todoItemProps, index) => (
        <TodoItem {...todoItemProps} key={`todo-item-${index}`} />
      ))}
    </div>
  );
};

export default TodoList;
