import React, { FC } from 'react';
import { Typography } from '@sensible/components';
import NavigationListItem from '../NavigationListItem/NavigationListItem';
import { NavigationLinkButtonProps } from './NavigationLinkButton.types';
import styles from './navigation-link-button.module.scss';

const NavigationLinkButton: FC<NavigationLinkButtonProps> = (props) => {
  return (
    <NavigationListItem>
      <button onClick={props.onClick}>
        <Typography variant="h5" color="snow" className={styles.link}>
          {props.label}
        </Typography>
      </button>
    </NavigationListItem>
  );
};

export default NavigationLinkButton;
