import { ReactElement, useState, useMemo, FC, useContext } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import BusinessOverviewStep from '../../components/BusinessOverviewStep/BusinessOverviewStep';
import SystemsUsedStep from '../../components/SystemsUsedStep/SystemsUsedStep';
import styles from './onboarding-form.module.scss';
import { Button, Grid } from '@sensible/components';
import { PartnerInputs } from '../../types';
import {
  BusinessOverviewInputs,
  PartnerPostParameters,
  SystemsUsedInputs,
} from '../../types/partnerInputs';
import { Generic } from '@sensible/utils';
import { useAuth0 } from '@auth0/auth0-react';
import { OrganizationContext } from '../../contexts/organizationContext';
import { Entity, err } from '@sensible/utils';
import PartnerAPI from '../../api/partner';

const BREADCRUMB_ITEMS = [
  'Business Overview',
  'Systems used',
  'Integration options & guides',
];

enum OnboardingSteps {
  'BUSINESS_OVERVIEW',
  'SYSTEMS_USED',
}
const StepCount = Object.keys(OnboardingSteps).filter((k) =>
  isNaN(parseInt(k))
).length;

const OnboardingForm: FC = () => {
  const [data, setData] = useState<Partial<PartnerInputs>>({});
  const [errors, setErrors] = useState<Generic.ErrorsFor<PartnerInputs>>({});
  const [step, setStep] = useState<OnboardingSteps>(
    OnboardingSteps.BUSINESS_OVERVIEW
  );
  const [submitError, setSubmitError] = useState<boolean>(false);

  const isFinalStep = step === StepCount - 1;

  const hasErrors = useMemo<boolean>(
    () => Object.values(errors).some((error) => !!error),
    [errors]
  );

  const validateData = (
    unvalidatedData: Partial<PartnerInputs>
  ): unvalidatedData is PartnerInputs => {
    const validationErrors = {};
    switch (step) {
      case OnboardingSteps.SYSTEMS_USED:
        const systemsUsedErrors: SystemsUsedInputs = {
          bookingPlatform: !data.bookingPlatform
            ? 'Booking Platform is required'
            : '',
          crmPlatform: !data.crmPlatform ? 'CRM Platform is required' : '',
          distributionChannel: !data.distributionChannel
            ? 'Distribution Channel is required'
            : '',
        };
        Object.assign(validationErrors, systemsUsedErrors);
        break;
      case OnboardingSteps.BUSINESS_OVERVIEW:
        const businessOverviewErrors: Generic.ErrorsFor<BusinessOverviewInputs> =
          {
            bookingVolume: !data.bookingVolume ? 'Select an Option' : '',
            businessType: !data.businessType ? 'Select an Option' : '',
            locale: !data.locale ? 'Select an Option' : '',
            phoneNumber:
              data.phoneNumber &&
              data.phoneNumber?.length !== 14 &&
              data.phoneNumber?.length !== 0
                ? 'Invalid Phone Number'
                : '',
          };
        Object.assign(validationErrors, businessOverviewErrors);
        break;
    }
    setErrors(validationErrors);
    return !Object.values(validationErrors).some((error) => !!error);
  };

  const { getAccessTokenSilently, user } = useAuth0();
  const organizationContext = useContext(OrganizationContext);

  const onSubmit = (partnerInputs: PartnerInputs): void => {
    void (async (): Promise<void> => {
      if (!organizationContext.organization?.displayName) {
        throw 'Organization Not Loaded from Auth0';
      }
      if (!user?.email) {
        throw 'User Email Missing';
      }
      const partner: PartnerPostParameters = {
        name: organizationContext.organization.displayName,
        authOrgID: organizationContext.organization.id,
        email: user.email,
        phone: '+1' + partnerInputs.phoneNumber.replace(/[^\d]/g, ''),
        locale: 'US', // TODO: Locale dropdown and locale field don't match, needs requirement discussion
        profiles: [
          {
            name: `${organizationContext.organization.displayName} - ${partnerInputs.businessType}`,
            type: partnerInputs.businessType,
            environment: Entity.PartnerEnvironment.Production,
            bookingPlatform: partnerInputs.bookingPlatform,
            distributionChannel: partnerInputs.distributionChannel,
            defaultStartHour: 8,
            defaultEndHour: 20,
            attributes: {
              bookingVolume: {
                name: 'booking_volume',
                type: 'string',
                value: partnerInputs.bookingVolume,
              },
              crmPlatform: {
                name: 'crm_platform',
                type: 'string',
                value: partnerInputs.crmPlatform,
              },
            },
          },
        ],
      };

      try {
        const token = await getAccessTokenSilently();
        await PartnerAPI.createPartner(token, partner);
        await organizationContext.refreshPartner();
      } catch (e) {
        throw err.handleError(e, 'error creating partner');
        setSubmitError(true);
      }
    })();
  };
  const handleSubmitStep = (): void => {
    const isValid = validateData(data);
    if (isValid) {
      if (!isFinalStep) {
        setStep(step + 1);
      } else {
        onSubmit(data);
      }
    }
  };

  const handleChange = (newData: Partial<PartnerInputs>): void => {
    setSubmitError(false);
    setData({ ...data, ...newData });
    if (hasErrors) {
      validateData(data);
    }
  };

  const goToPreviousStep = (): void => {
    setStep(Math.max(step - 1, 0));
  };

  const OnboardingStepMap: Record<OnboardingSteps, ReactElement> = {
    [OnboardingSteps.BUSINESS_OVERVIEW]: (
      <BusinessOverviewStep
        data={data}
        errors={errors}
        onChange={handleChange}
      />
    ),
    [OnboardingSteps.SYSTEMS_USED]: (
      <SystemsUsedStep data={data} errors={errors} onChange={handleChange} />
    ),
  };

  const renderPreviousButton = (): ReactElement => {
    if (step) {
      return (
        <Button
          clickHandling={goToPreviousStep}
          label="Back"
          errorMsg=""
          colorVariant="snow"
        />
      );
    }
    return <></>;
  };

  const renderNextButton = (): ReactElement => {
    return (
      <Button
        clickHandling={handleSubmitStep}
        label={isFinalStep ? 'Submit' : 'Next'}
        errorMsg={submitError ? 'Error creating new Partner' : ''}
      />
    );
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Breadcrumb
            className={styles.breadcrumb}
            items={BREADCRUMB_ITEMS}
            current={step}
          />
          <div className={styles.formFieldsContainer}>
            {OnboardingStepMap[step]}
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={styles.nextButtonContainer}>{renderNextButton()}</div>
        </Grid>
        <Grid item xs={12}>
          {renderPreviousButton()}
        </Grid>
      </Grid>
    </>
  );
};

export default OnboardingForm;
