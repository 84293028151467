import { useAuth0 } from '@auth0/auth0-react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import React, { useContext } from 'react';
import { OrganizationContext } from './contexts/organizationContext';
import RouteElement from './router/RouteElement';
import routes from './router/RouteConfig';

const App: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const location = useLocation();
  const organizationEnvelope = useContext(OrganizationContext);

  if (isLoading || (isAuthenticated && !organizationEnvelope.ready)) {
    return <div id="partner-portal-loading" />;
  }

  const renderWildcardRoute = (): React.ReactElement => {
    if (isAuthenticated) {
      return <Route path="*" element={<Navigate to="/" />} />;
    } else if (!isLoading) {
      void loginWithRedirect({ appState: { path: location.pathname } });
    }

    return <></>;
  };
  return (
    <Routes>
      {routes.map((route) => {
        return (
          <Route
            key={route.path}
            path={route.path}
            element={<RouteElement route={route} />}
          />
        );
      })}
      {renderWildcardRoute()}
    </Routes>
  );
};

export default App;
