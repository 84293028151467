import { FC } from 'react';
import { Typography } from '@sensible/components';
import styles from './page-title.module.scss';

const PageTitle: FC = ({ children }) => {
  return (
    <Typography variant="h4" className={styles.title}>
      {children}
    </Typography>
  );
};

export default PageTitle;
