import {
  Home,
  GuideApiIntegration,
  ApiKeys,
  GuidePostBooking,
  HelpPage,
} from '../pages';
import { PageProps } from '../pages';
import { LayoutProps, PortalLayout } from '../layout';
import React from 'react';

export interface IRouteConfig {
  path: string;
  name: string;
  element: React.FC<PageProps>;
  layout: React.FC<LayoutProps>;
  private: boolean;
}

const routes: IRouteConfig[] = [
  {
    path: '/',
    name: 'Home Page',
    element: Home,
    layout: PortalLayout,
    private: true,
  },
  {
    path: '/keys-and-ids',
    name: 'API Keys & IDs',
    element: ApiKeys,
    layout: PortalLayout,
    private: true,
  },
  {
    path: '/guides/post-booking',
    name: 'Guides: Post-Booking',
    element: GuidePostBooking,
    layout: PortalLayout,
    private: true,
  },
  {
    path: '/guides/api-integration',
    name: 'Guides: API Integration',
    element: GuideApiIntegration,
    layout: PortalLayout,
    private: true,
  },
  {
    path: '/help',
    name: 'help',
    element: HelpPage,
    layout: PortalLayout,
    private: true,
  },
];

export default routes;
