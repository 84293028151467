import { FC } from 'react';
import { Typography } from '@sensible/components';
import styles from './section-header.module.scss';

const SectionHeader: FC = ({ children }) => {
  return (
    <Typography variant="h5" className={styles.header}>
      {children}
    </Typography>
  );
};

export default SectionHeader;
