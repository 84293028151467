import { Entity } from '@sensible/utils';

export interface BusinessOverviewInputs {
  phoneNumber: string;
  locale: BusinessLocales;
  businessType: Entity.PartnerIntegration;
  bookingVolume: BookingVolumes;
}

export interface SystemsUsedInputs {
  crmPlatform: string;
  bookingPlatform: string;
  distributionChannel: string;
}

export enum BusinessLocales {
  NorthAmerica = 'North America',
  SouthAmerica = 'South America',
  Europe = 'Europe',
  Asia = 'Asia',
  Africa = 'Africa',
  Australia_Oceania = 'Australia/Oceania',
}

export enum BookingVolumes {
  Tier1 = 'Less than $1,000,000',
  Tier2 = '$1,000,000 - $10,000,000',
  Tier3 = '$10,000,000 - $100,000,000',
  Tier4 = '$100,000,000 - $500,000,000',
  Tier5 = '$500,000,000 - $1 billion',
  Tier6 = '$1B+',
}

export interface PartnerInputs
  extends BusinessOverviewInputs,
    SystemsUsedInputs {}

export interface PartnerPostParameters extends Omit<Entity.Partner, 'id'> {
  profiles: PartnerProfilePostParameters[];
}

export interface PartnerProfilePostParameters extends Entity.PartnerProfile {
  attributes: {
    bookingVolume?: {
      name: 'booking_volume';
      type: 'string';
      value: string;
    };
    crmPlatform?: {
      name: 'crm_platform';
      type: 'string';
      value: string;
    };
  };
}
