import { Entity } from '@sensible/utils';
import {
  DropdownInputSingleSelect,
  FormattedTextInput,
} from '@sensible/components';
import { BusinessOverviewStepProps } from './BusinessOverviewStep.types';
import {
  BUSINESS_TYPE_DROPDOWN_OPTIONS,
  LOCATION_DROPDOWN_OPTIONS,
  BOOKING_VOLUME_DROPDOWN_OPTIONS,
} from './constants';
import { BookingVolumes, BusinessLocales } from '../../types/partnerInputs';

const BusinessOverviewStep: React.FunctionComponent<
  BusinessOverviewStepProps
> = ({ data, errors, onChange }) => {
  return (
    <>
      <DropdownInputSingleSelect
        options={BUSINESS_TYPE_DROPDOWN_OPTIONS}
        label="Business Type"
        errorMsg={errors.businessType}
        onChangeOption={(option): void =>
          onChange({ businessType: option.value as Entity.PartnerIntegration })
        }
        optionsFormat="none"
        value={data.businessType}
      />
      <DropdownInputSingleSelect
        options={LOCATION_DROPDOWN_OPTIONS}
        label="Geographies you operate in"
        errorMsg={errors.locale}
        onChangeOption={(option): void =>
          onChange({ locale: option.value as BusinessLocales })
        }
        optionsFormat="none"
        value={data.locale}
      />
      <DropdownInputSingleSelect
        options={BOOKING_VOLUME_DROPDOWN_OPTIONS}
        label="Annual Booking volume"
        errorMsg={errors.bookingVolume}
        onChangeOption={(option): void =>
          onChange({ bookingVolume: option.value as BookingVolumes })
        }
        optionsFormat="none"
        value={data.bookingVolume}
      />
      <FormattedTextInput.PhoneNumber
        handleChange={(value): void => {
          return onChange({
            phoneNumber: value,
          });
        }}
        label="Phone Number"
        value={data.phoneNumber || ''}
        errorMsg={errors.phoneNumber || ''}
      />
    </>
  );
};

export default BusinessOverviewStep;
