import { FC } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import { EnvironmentKeysProps } from '../../components/EnvironmentKeys/EnvironmentKeys.types';
import EnvironmentKeys from '../../components/EnvironmentKeys/EnvironmentKeys';

const KeysList: FC = () => {
  const environmentKeysProps: EnvironmentKeysProps[] = [
    {
      environmentName: 'Production',
      partnerId: 'db7cd25d-3186-49cf-af42-3097119a0ab0',
    },
    {
      environmentName: 'Sandbox',
      partnerId: 'Qw9d25d-3186-49cf-af42-3097119a0362',
    },
  ];
  return (
    <>
      <PageTitle>API Keys & IDs</PageTitle>
      {environmentKeysProps.map((ekp, index) => (
        <EnvironmentKeys {...ekp} key={`environment-keys-${index}`} />
      ))}
    </>
  );
};

export default KeysList;
