import { TextInput } from '@sensible/components';
import { SystemsUsedStepProps } from './SystemsUsedStep.types';

const SystemsUsedStep: React.FunctionComponent<SystemsUsedStepProps> = ({
  data,
  errors,
  onChange,
}) => {
  return (
    <>
      <TextInput
        label="Booking Platform"
        value={data.bookingPlatform || ''}
        handleChange={(value): void => onChange({ bookingPlatform: value })}
        errorMsg={errors.bookingPlatform || ''}
      />

      <TextInput
        label="Distribution Channel"
        value={data.distributionChannel || ''}
        handleChange={(value): void => onChange({ distributionChannel: value })}
        errorMsg={errors.distributionChannel || ''}
      />

      <TextInput
        label="CRM/Email Platform"
        value={data.crmPlatform || ''}
        handleChange={(value): void => onChange({ crmPlatform: value })}
        errorMsg={errors.crmPlatform || ''}
      />
    </>
  );
};

export default SystemsUsedStep;
