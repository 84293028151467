import { err } from '@sensible/utils';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { PartnerPostParameters } from '../types/partnerInputs';
import { Entity } from '@sensible/utils';

const createPartner = async (
  token: string,
  partner: PartnerPostParameters
): Promise<Entity.Partner> => {
  try {
    const res: AxiosResponse<Entity.Partner> = await axios.post(
      '/api/partner',
      partner,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    if (res.status < 200 || res.status > 300) {
      throw new Error(`${res.status} error`);
    }
    return res.data;
  } catch (e) {
    throw err.handleError(e, 'error creating partner');
  }
};

const getPartner = async (
  token: string
): Promise<Entity.Partner | undefined> => {
  try {
    const res: AxiosResponse<Entity.Partner> = await axios.get('/api/partner', {
      headers: { Authorization: `Bearer ${token}` },
    });

    if (res.status === 404) return undefined;

    if (res.status < 200 || res.status > 300) {
      throw new Error(`${res.status} error`);
    }

    return res.data;
  } catch (e: unknown) {
    if ((e as AxiosError).response?.status === 404) {
      return undefined;
    }
    throw err.handleError(e, 'error fetching partner');
  }
};

export default {
  createPartner,
  getPartner,
};
