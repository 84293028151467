import { Entity } from '@sensible/utils';
import { BookingVolumes, BusinessLocales } from '../../types/partnerInputs';

// TODO: #1099
export interface IDropdownOption {
  label?: string;
  value: string;
}

export interface IBusinessTypeDropdownOption extends IDropdownOption {
  value: Entity.PartnerIntegration;
}

export const BUSINESS_TYPE_DROPDOWN_OPTIONS: IBusinessTypeDropdownOption[] = [
  {
    label: 'Post-Booking',
    value: Entity.PartnerIntegration.PostBooking,
  },
];

export const LOCATION_DROPDOWN_OPTIONS = Object.values(BusinessLocales).map(
  (locale) => ({ value: locale })
);

export const BOOKING_VOLUME_DROPDOWN_OPTIONS = Object.values(
  BookingVolumes
).map((volume) => ({ value: volume }));
