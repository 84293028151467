import React from 'react';
import styles from './navigation-mobile.module.scss';
import { assets } from '@sensible/components';

const NavigationMobile: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.logoContainer}>
        <img
          src={assets.png.sensible.DarkSensibleLogo}
          className={styles.logo}
          alt="Sensible Weather logo"
        />
      </div>
      <div>TODO</div>
    </div>
  );
};

export default NavigationMobile;
