import { PartnerHeaderProps } from './PartnerHeader.types';
import { Typography } from '@sensible/components';
import { assets } from '@sensible/components';
import styles from './partner-header.module.scss';

const PartnerHeader: React.FC<PartnerHeaderProps> = ({ partnerDisplay }) => {
  const Logo = assets.svg.sensible.SensibleLogo;

  if (!partnerDisplay) {
    return <div id="partner-display-blank" className={styles.container} />;
  }

  return (
    <div id="partner-display-rendered" className={styles.container}>
      <Typography variant="h4" className={styles.logo}>
        {partnerDisplay}
      </Typography>
      <Typography variant="h4" className={styles.plus}>
        +
      </Typography>
      <div className={styles.badgeWrapper}>
        <Logo />
      </div>
    </div>
  );
};

export default PartnerHeader;
