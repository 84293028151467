import { useContext, useEffect, useState } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { ConfigContext } from '../contexts/configContext';
import { Config } from '../types/config';

const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [clientID, setClientID] = useState('');
  const [domain, setDomain] = useState('');
  const [audience, setAudience] = useState('');

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);

  const configEnvelope = useContext(ConfigContext);

  useEffect(() => {
    if (configEnvelope.ready) {
      if (configEnvelope.error) {
        setError(true);
        setIsLoading(false);
        return;
      }

      setError(false);
      const { authDomain, reactAuthClientID, partnerAuthAudience } =
        configEnvelope.config as Config;

      setDomain(authDomain);
      setClientID(reactAuthClientID);
      setAudience(partnerAuthAudience);

      setIsLoading(false);
    }
  }, [configEnvelope]);

  // TODO #979: Replace with error screen
  if (error) {
    return <div id="auth0-error">Error</div>;
  }

  if (isLoading) {
    return <div id="auth0-loading" />;
  }

  return (
    <Auth0Provider
      clientId={clientID}
      domain={domain}
      audience={audience}
      redirectUri={window.location.origin}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithNavigate;
