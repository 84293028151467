import React, { createContext, useEffect, useState } from 'react';
import AuthAPI from '../api/auth';
import PartnerAPI from '../api/partner';
import { useAuth0 } from '@auth0/auth0-react';
import {
  OrganizationContextValue,
  OrganizationEnvelope,
} from './organizationContext.types';

const DefaultOrganizationEnvelope = {
  ready: false,
  loading: false,
  refreshPartner: (): Promise<void> => {
    throw 'Called refreshPartner before envelope was hydrated';
  },
};

const OrganizationContext = createContext<OrganizationContextValue>(
  DefaultOrganizationEnvelope
);

const OrganizationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [organizationEnvelope, setOrganizationEnvelope] =
    useState<OrganizationEnvelope>(DefaultOrganizationEnvelope);

  useEffect(() => {
    if (
      !isAuthenticated ||
      organizationEnvelope.ready ||
      organizationEnvelope.loading
    ) {
      return;
    }

    void (async (): Promise<void> => {
      setOrganizationEnvelope({ ready: false, loading: true });
      const token = await getAccessTokenSilently();
      const organization = await AuthAPI.getOrganization(token);
      const partner = await PartnerAPI.getPartner(token);
      setOrganizationEnvelope({
        ready: true,
        loading: false,
        organization,
        partner,
      });
    })();
  }, [organizationEnvelope, isAuthenticated, getAccessTokenSilently]);

  const refreshPartner = async (): Promise<void> => {
    const token = await getAccessTokenSilently();
    const partner = await PartnerAPI.getPartner(token);
    setOrganizationEnvelope((prevOrganizationEnvelope) => ({
      ...prevOrganizationEnvelope,
      partner,
    }));
  };
  return (
    <OrganizationContext.Provider
      value={{ ...organizationEnvelope, refreshPartner }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};

export { OrganizationProvider, OrganizationContext };
