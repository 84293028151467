import React, { FC, useContext } from 'react';
import { IRouteConfig } from './RouteConfig';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { OrganizationContext } from '../contexts/organizationContext';
import { OnboardingLayout } from '../layout';
import { OnboardingForm } from '../containers';

const RouteElement: FC<{ route: IRouteConfig }> = ({ route }) => {
  const organizationEnvelope = useContext(OrganizationContext);
  if (organizationEnvelope.ready && !organizationEnvelope.partner) {
    return (
      <OnboardingLayout>
        <OnboardingForm />
      </OnboardingLayout>
    );
  }

  const renderRouteElement: FC = () => {
    return (
      <route.layout>
        <route.element />
      </route.layout>
    );
  };
  const Component = route.private
    ? withAuthenticationRequired(renderRouteElement, {
        onRedirecting: () => {
          return <div />;
        },
      })
    : renderRouteElement;

  return <Component />;
};

export default RouteElement;
