import { FC, useState } from 'react';
import { TextParameterItemProps } from './TextParameterItem.types';
import { Typography, Paper } from '@sensible/components';
import styles from './text-parameter-item.module.scss';

const TextParameterItem: FC<TextParameterItemProps> = ({
  label,
  value,
  disableCopy,
  altHoverText,
}) => {
  const COPY_HOVER_TEXT = 'Click to Copy';

  const [hoverText, setHoverText] = useState(COPY_HOVER_TEXT);
  const [showHoverText, setShowHoverText] = useState(false);

  const copy = (): void => {
    if (!disableCopy && !altHoverText) {
      void navigator.clipboard.writeText(value);
      setHoverText('Copied!');
    }
  };

  const resetMessage = (): void => {
    setHoverText(COPY_HOVER_TEXT);
    setShowHoverText(false);
  };

  const showMessage = (): void => {
    if (!disableCopy) {
      setShowHoverText(true);
    }
  };

  const getHoverText = (): string => {
    return showHoverText ? altHoverText || hoverText : '';
  };

  return (
    <div className={styles.container}>
      <Typography variant="body-4" className={styles.label}>
        {label}
      </Typography>
      <div
        onClick={copy}
        onMouseLeave={resetMessage}
        onMouseEnter={showMessage}
        role="presentation"
      >
        <Paper className={styles.paper}>
          <Typography variant="body-3" className={styles.value}>
            {value}
          </Typography>
        </Paper>
        <Typography variant="body-4" className={styles.hover}>
          {getHoverText()}
        </Typography>
      </div>
    </div>
  );
};

export default TextParameterItem;
