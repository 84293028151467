import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Typography } from '@sensible/components';
import { NavigationAccordionItemProps } from './NavigationAccordionItem.props';
import styles from './navigation-accordion-item.module.scss';

const NavigationAccordionItem: React.FC<NavigationAccordionItemProps> = (
  props
) => {
  const location = useLocation();
  const className =
    location.pathname === props.to ? styles.currentLink : styles.link;
  return (
    <li className={styles.accordionListItem}>
      <Link {...props}>
        <Typography variant="body-4" color="snow" className={className}>
          {props.label}
        </Typography>
      </Link>
    </li>
  );
};

export default NavigationAccordionItem;
