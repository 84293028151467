import { err } from '@sensible/utils';
import axios, { AxiosResponse } from 'axios';
import { assertsIsOrganization, IOrganization } from '../types/organization';

const getOrganization = async (token: string): Promise<IOrganization> => {
  const URL = '/api/auth/organization';

  try {
    const res: AxiosResponse<Record<string, unknown>> = await axios.get(URL, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status < 200 || res.status >= 300) {
      throw new Error(`${res.status} error`);
    }

    assertsIsOrganization(res.data);
    return res.data;
  } catch (e) {
    throw err.handleError(e, 'error fetching organization data');
  }
};

export default {
  getOrganization,
};
