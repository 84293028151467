import { FC } from 'react';
import PageTitle from '../../components/PageTitle/PageTitle';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import { Typography } from '@sensible/components';

const Help: FC = () => {
  return (
    <>
      <PageTitle>Help</PageTitle>
      <SectionHeader>Support</SectionHeader>
      <Typography variant="body-3">
        Our support team can be reached by emailing{' '}
        <a href="mailto:support@sensibleweather.com">
          support@sensibleweather.com
        </a>{' '}
        or by using the chat function.
      </Typography>
    </>
  );
};

export default Help;
